import { RootState } from "../reducers";
import { createSelector } from "redux-starter-kit";
import {ITreatmentPlansBaseState} from "./treatmentPlansListSelectors";

export interface ITreatmentPlansItemState extends ITreatmentPlansBaseState {
  retrieved: any; // object
  eventSource: EventSource;
}

export interface IDeleteTreatmentPlansItemState extends ITreatmentPlansBaseState {
  deleted: any; // model for aftercare
}

export interface ICreateTreatmentPlansItemState extends ITreatmentPlansBaseState {
  created: any; // model for aftercare
}

export interface IUpdateTreatmentPlansItemState {
  retrieved: any; // model for aftercare
  retrieveError: string;
  retrieveLoading: boolean;
  updateError: string;
  updateLoading: boolean;
  eventSource: EventSource;
  updated: any; // model for aftercare
}

export const selectTreatmentPlansItem = (state: RootState): ITreatmentPlansItemState => {
  return state.treatmentPlans.show;
};

export const selectDeleteTreatmentPlansItem = (state: RootState): IDeleteTreatmentPlansItemState => {
  return state.treatmentPlans.del;
};

export const selectCreateTreatmentPlansItem = (state: RootState): ICreateTreatmentPlansItemState => {
  return state.treatmentPlans.create;
};

export const selectUpdateTreatmentPlansItem = (state: RootState): IUpdateTreatmentPlansItemState => {
  return state.treatmentPlans.update;
};

export const retrievedTreatmentPlansItemSelector = createSelector(
  [selectTreatmentPlansItem],
  (state: ITreatmentPlansItemState) => state.retrieved
);

export const treatmentPlansItemLoadingSelector = createSelector(
  [selectTreatmentPlansItem],
  (state: ITreatmentPlansItemState) => state.loading
);

export const treatmentPlansItemErrorSelector = createSelector(
  [selectTreatmentPlansItem],
  (state: ITreatmentPlansItemState) => state.error
);

export const treatmentPlansItemEventSourceSelector = createSelector(
 [selectTreatmentPlansItem],
 (state: ITreatmentPlansItemState) => state.eventSource
);

export const treatmentPlansItemDeleteErrorSelector = createSelector(
  [selectDeleteTreatmentPlansItem],
  (state: IDeleteTreatmentPlansItemState) => state.error
);

export const treatmentPlansItemDeleteLoadingSelector = createSelector(
  [selectDeleteTreatmentPlansItem],
  (state: IDeleteTreatmentPlansItemState) => state.loading
);

export const treatmentPlansItemDeletedSelector = createSelector(
  [selectDeleteTreatmentPlansItem],
  (state: IDeleteTreatmentPlansItemState) => state.deleted
);

export const treatmentPlansItemCreateErrorSelector = createSelector(
  [selectCreateTreatmentPlansItem],
  (state: ICreateTreatmentPlansItemState) => state.error
);

export const treatmentPlansItemCreateLoadingSelector = createSelector(
  [selectCreateTreatmentPlansItem],
  (state: ICreateTreatmentPlansItemState) => state.loading
);

export const treatmentPlansItemCreatedSelector = createSelector(
  [selectCreateTreatmentPlansItem],
  (state: ICreateTreatmentPlansItemState) => state.created
);

export const treatmentPlansItemUpdateRetrievedSelector = createSelector(
  [selectUpdateTreatmentPlansItem],
  (state: IUpdateTreatmentPlansItemState) => state.retrieved
);

export const treatmentPlansItemUpdateRetrieveErrorSelector = createSelector(
  [selectUpdateTreatmentPlansItem],
  (state: IUpdateTreatmentPlansItemState) => state.retrieveError
);

export const treatmentPlansItemUpdateRetrieveLoadingSelector = createSelector(
  [selectUpdateTreatmentPlansItem],
  (state: IUpdateTreatmentPlansItemState) => state.retrieveLoading
);

export const treatmentPlansItemUpdateErrorSelector = createSelector(
  [selectUpdateTreatmentPlansItem],
  (state: IUpdateTreatmentPlansItemState) => state.updateError
);

export const treatmentPlansItemUpdateLoadingSelector = createSelector(
  [selectUpdateTreatmentPlansItem],
  (state: IUpdateTreatmentPlansItemState) => state.updateLoading
);

export const treatmentPlansItemUpdatedSelector = createSelector(
  [selectUpdateTreatmentPlansItem],
  (state: IUpdateTreatmentPlansItemState) => state.updated
);

export const treatmentPlansItemUpdateEventSourceSelector = createSelector(
  [selectUpdateTreatmentPlansItem],
  (state: IUpdateTreatmentPlansItemState) => state.eventSource
);
