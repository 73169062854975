import React, {Component} from 'react';
import {
    BasicModal,
    CustomCard,
    CustomCardType,
    Form,
    FormControlChangeType,
    IFormConfig,
    rescheduleInquiryAPI,
    RestQueryParams,
    Translation
} from "common-web";
import {BehaviorSubject, of, Subscription} from "rxjs";
import {editInsuranceDateFormConfig} from "./editInsuranceDateFormConfig";
import {catchError, filter, tap} from "rxjs/operators";
import moment from "moment/moment";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";

interface IEditInsuranceReportProps {
    readonly authToken: string | null;
    readonly isModalShown: boolean;
    readonly toggleModal: (inquiry?: {[key: string]: any}) => void;
    readonly inquiry: {[key: string]: any} | null;
    readonly getInquiryList: (searchParams: typeof RestQueryParams) => void;
}

interface IEditInsuranceDateState {
    isLoading: boolean;
    formConfig: typeof IFormConfig;
    value: {[key: string]: any} | null;
}


class EditInsuranceDate extends Component<IEditInsuranceReportProps, IEditInsuranceDateState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IEditInsuranceReportProps) {
        super(props);

        this.state = {
            isLoading: false,
            formConfig: null,
            value: null,
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.setFormConfig();
        this.setDefaultFormValues();

        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.onFormValueChange(data.value)),
            ).subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<IEditInsuranceReportProps>) {
        if (this.props.inquiry !== prevProps.inquiry && this.props.inquiry) {
            this.setDefaultFormValues();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }


    render() {
        return (
            <BasicModal isModalShown={this.props.isModalShown} closeModal={this.props.toggleModal}>
                <CustomCard showLocalLoader={this.state.isLoading} type={CustomCardType.MODAL_CARD}>
                    <CustomCard.Body>
                        <div className="modal-header">
                            <Translation text={'inquiries.inquiryList.editDateForm.title'}/>
                            <button className="btn-modal-close" onClick={() => this.props.toggleModal()}>
                                <span className="feather icon-x"/>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Form config={this.state.formConfig}
                                  onValueStateChange={this.onValueStateChange}
                                  submitForm={this.editInsurance}
                                  value={this.state.value}
                                  controlName={'addTreatmentCategoryForm'}/>
                        </div>
                    </CustomCard.Body>
                </CustomCard>
            </BasicModal>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private editInsurance = () => {
        if (this.props.inquiry && this.state.value && this.props.authToken) {
            this.setState({isLoading: true});
            rescheduleInquiryAPI(this.props.authToken, this.props.inquiry.id, this.state.value.startDate).pipe(
                tap(() => {
                    this.setState({isLoading: false});
                    this.props.toggleModal();
                    this.alertManager.addAlert('inquiries.inquiryList.editDateForm.dateChangeSuccess');
                    this.props.getInquiryList(new RestQueryParams());
                }),
                catchError((error: any) => {
                    this.setState({isLoading: false});
                    this.alertManager.handleApiError(error);
                    return of();
                })
            ).subscribe()
        }
    }

    private onFormValueChange = (value: any) => {
        const mappedValue: any = {};
        Object.keys(value).forEach((key: string) => {
            let fieldValue = value[key];

            if (undefined === fieldValue || null === fieldValue) {
                mappedValue[key] = null;

                return;
            }

            mappedValue[key] = fieldValue;
        })

        this.setState({value: mappedValue});
    };

    private setFormConfig = () => {
        const formConfig = editInsuranceDateFormConfig();
        this.setState({formConfig});
    }

    private setDefaultFormValues = () => {
        const inquiryStartDate = this.props.inquiry?.from;

        const values = {
            startDate: inquiryStartDate ? moment(inquiryStartDate).format('YYYY-MM-DD') : null,
        }

        this.setState({value: values});
    }
}


export default EditInsuranceDate;
