import React from 'react';
import {Link, RouteComponentProps, withRouter} from 'react-router-dom';
import {list, reset} from '../../actions/treatmentPlans/list';
import {authTokenSelector, CustomCard, RestQueryParams, CustomPagination, Translation} from "common-web";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import {IAlertManagerService} from '../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {
    treatmentPlansListErrorSelector,
    treatmentPlansListEventSourceSelector,
    treatmentPlansListLoadingSelector,
    retrievedTreatmentPlansListSelector
} from "../../store/selectors/treatmentPlansListSelectors";
import SortingButtons from "./SortingButtons";
import {RootState} from "../../store/reducers";


export enum EventType {
    AFTERCARE = 'after_care',
    TRAVEL_PLAN = 'travel_plan'
}

export enum EventStatus {
    DRAFT = 'draft',
    EXPIRED = 'expired',
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    INVITED = 'invited'
}

interface IConnectedTreatmentPlannerListProps {
    readonly retrieved: any;
    readonly loading: boolean;
    readonly error: string;
    readonly eventSource: EventSource;
    readonly list: any;
    readonly reset: any;
    readonly authToken: string;
}

interface ITreatmentPlannerListProps extends IConnectedTreatmentPlannerListProps,
    RouteComponentProps,
    WithTranslation {
}

interface ITreatmentPlannerListState {
    isPatientSortedAsc: boolean | null;
    isTreatmentTypeSortedAsc: boolean | null;
    isStatusSortedAsc: boolean | null;
}

class TreatmentPlannerList extends React.Component<ITreatmentPlannerListProps, ITreatmentPlannerListState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: ITreatmentPlannerListProps) {
        super(props);

        this.state = {
            isPatientSortedAsc: null,
            isTreatmentTypeSortedAsc: null,
            isStatusSortedAsc: null,
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {}

    componentDidUpdate(
        prevProps: Readonly<ITreatmentPlannerListProps>,
        prevState: Readonly<ITreatmentPlannerListState>,
        snapshot?: any
    ): void {
        if (this.props.error !== prevProps.error) {
            this.alertManager.handleApiError(this.props.error);
        }
    }

    componentWillUnmount() {
        this.props.reset(this.props.eventSource);
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title">
                                <Translation text={'treatmentPlanner.title'}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 d-flex justify-content-between">
                        <div className="action-container">

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <CustomCard showLocalLoader={this.props.loading}>
                            <CustomCard.Body>
                                {this.renderAfterCareList()}
                            </CustomCard.Body>
                        </CustomCard>
                        <CustomPagination retrieved={this.props.retrieved}
                                          basePath="dashboard"
                                          path="aftercare"
                                          provider={this.getTreatmentPlannerList}/>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderAfterCareList = () => {
        if (!this.props.retrieved ||
            !this.props.retrieved['hydra:member'] ||
            !Array.isArray(this.props.retrieved['hydra:member']) ||
            !this.props.retrieved['hydra:member'].length) {
            return <Translation text={'treatmentPlanner.noDataAvailable'}/>;
        }

        return (
            <React.Fragment>
                <table className="data-table">
                    <thead>
                        <tr>
                            <th>
                                <Translation text={'treatmentPlanner.table.treatmentType'}/>
                                <SortingButtons sortDataDesc={this.sortDataTableDesc}
                                                sortDataAsc={this.sortDataTableAsc}
                                                isSortingAsc={this.state.isTreatmentTypeSortedAsc}
                                                sortingParam={'treatmentType.name'}/>
                            </th>
                            <th>
                                <Translation text={'treatmentPlanner.table.status'}/>
                                <SortingButtons sortDataDesc={this.sortDataTableDesc}
                                                sortDataAsc={this.sortDataTableAsc}
                                                isSortingAsc={this.state.isStatusSortedAsc}
                                                sortingParam={'aftercareStatus'}/>
                            </th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderTableRows()}
                    </tbody>
                </table>
            </React.Fragment>
        )
    };

    private renderTableRows() {
        const rows: any[] = [],
            list = this.props.retrieved['hydra:member'].filter((item: {[key: string]: any}) => {
                return item.treatmentPlanStatus !== EventStatus.DRAFT;
            });

        list.map((item: any) => {
            return rows.push((
                <tr key={item['id']}>
                    <td>
                        {item.treatmentType.name}
                    </td>
                    <td>
                        <div className={`status-wrapper ${item.treatmentPlanStatus}`}>
                            <p className="status">
                                <Translation text={`treatmentPlanner.statuses.${item.treatmentPlanStatus}`}/>
                            </p>
                        </div>
                    </td>
                    <td className="align-middle text-right">
                        <Link className="btn btn-action" to={`/dashboard/treatmentPlans/${item['id']}`}>
                            <span className="feather icon-edit"/>
                        </Link>
                    </td>
                </tr>
            ))
        });
        return rows;
    }

    private getTreatmentPlannerList = (searchParams: typeof RestQueryParams) => {
        searchParams = searchParams.add('order[createdAt]', 'DESC');
        searchParams = searchParams.add('itemsPerPage', '10');
        this.props.list(
            `treatment_plans${searchParams.prepareQuery()}`,
            this.props.authToken
        );
    };

    private sortDataTableAsc = (param: string) => {
        this.props.list(
            `treatment_plans?page=1&itemsPerPage=10&order[${param}]=asc`,
            this.props.authToken
        );

        this.setState({
            isTreatmentTypeSortedAsc: null,
            isPatientSortedAsc: null,
            isStatusSortedAsc: null
        });

        if (param.includes('patient')) {
            this.setState({isPatientSortedAsc: true});
        }

        if (param.includes('treatment')) {
            this.setState({isTreatmentTypeSortedAsc: true});
        }

        if (param.toLocaleLowerCase().includes('status')) {
            this.setState({isStatusSortedAsc: true});
        }
    };

    private sortDataTableDesc = (param: string) => {
        this.props.list(
            `treatment_plans?page=1&itemsPerPage=10&order[${param}]=desc`,
            this.props.authToken
        );

        this.setState({
            isTreatmentTypeSortedAsc: null,
            isPatientSortedAsc: null,
            isStatusSortedAsc: null
        });

        if (param.includes('patient')) {
            this.setState({isPatientSortedAsc: false});
        }

        if (param.includes('treatment')) {
            this.setState({isTreatmentTypeSortedAsc: false});
        }

        if (param.toLocaleLowerCase().includes('status')) {
            this.setState({isStatusSortedAsc: false});
        }
    };
}

export default withTranslation()(connect(
    (state: RootState) => ({
        retrieved: retrievedTreatmentPlansListSelector(state),
        loading: treatmentPlansListLoadingSelector(state),
        error: treatmentPlansListErrorSelector(state),
        eventSource: treatmentPlansListEventSourceSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        list,
        reset,
    }
)(withRouter(TreatmentPlannerList)));
