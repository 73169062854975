import React from 'react';
import {
    accountSelector,
    isAuthenticatedSelector,
    Loader,
    NavBar,
    Sidebar,
    NotFound,
    setAuthState,
    Toast,
    usernameSelector,
    authTokenSelector,
    accountIdSelector,
    getAccountDataAPI,
    setAccountState,
    AllUserConnections,
    withChatConnection,
    Chat,
    ChatMessage,
    withChatContactsList,
    ChatContact,
    sendMessageToOfflineAPI,
    NotificationMessage,
    postDelayedMessageAPI,
    saveFile,
    downloadFile
} from 'common-web';
import {Route, Redirect, RouteComponentProps, Switch, withRouter} from 'react-router-dom';
import navigation from './menu-items';
import {connect} from 'react-redux';
// import Dashboard from '../Dashboard';
import InsuranceList from '../Insurances';
import InsuranceView from '../Insurances/InsuranceView';
import {RootState} from '../../store/reducers';
import {showLoaderSelector} from '../../store/selectors/sagaSelectors';
import Profile from '../Profile';
import Consultations from '../Consultations';
import {catchError, map} from "rxjs/operators";
import {of, Subscription, Subject} from 'rxjs';
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {IAlertManagerService} from "../../service/alertManagerService";
import TreatmentPlannerList from '../TreatmentPlannerList';
import TreatmentPlannerView from '../TreatmentPlannerList/TreatmentPlannerView';

interface IConnectedPanelHostProps {
    readonly setAuthState: typeof setAuthState;
    readonly setAccountState: typeof setAccountState;
    readonly showLoader: boolean;
    readonly isAuthenticated: boolean;
    readonly authToken: string;
    readonly accountId: string;
    readonly account: any;
    readonly username: string;
    readonly userId: string;
}

interface IExternalPanelHostProps {
    fullWidthLayout?: any;
    peerConnections: typeof AllUserConnections;
    addMessage: (message: typeof ChatMessage, room: string) => void
    setSelectedChatRoomId: (roomId:string) => void;
    selectedChatRoomId: string;
    hasUnreadMessages: string[];
    hasNodeConnection: boolean;
    allowedChannels: typeof ChatContact[];
    errors: Subject<Error>
}

interface IPanelHostProps extends IConnectedPanelHostProps,
    IExternalPanelHostProps,
    RouteComponentProps {
}

interface IPanelHostState {
    isSidebarCollapsed: boolean;
}


class PanelHost extends React.Component<IPanelHostProps, IPanelHostState> {
    private navMenu: any[];
    private subscription: Subscription | null = null;

    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IPanelHostProps) {
        super(props);

        this.state = {
            isSidebarCollapsed: false
        };

        this.navMenu = [
            {
                url: '/dashboard/profile',
                icon: 'icon-user',
                title: 'Profile'
            },
            // {
            //     url: '/dashboard/settings',
            //     icon: 'icon-settings',
            //     title: 'Settings'
            // },
            {
                url: '#',
                icon: 'icon-log-out',
                title: 'Logout',
                onClick: this.onLogout
            }
        ];
        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        if (this.props.accountId) {
            this.subscription = getAccountDataAPI(this.props.accountId, this.props.authToken).pipe(
                map((account: any) => {
                    if (this.props.account.firstName !== account.firstName ||
                        this.props.account.lastName !== account.lastName) {

                        let accountData = {
                            id: account.id,
                            firstName: account.firstName,
                            lastName: account.lastName,
                            birthDate: account.birthdate,
                            address: {
                                addressLine1: account.address.addressLine1,
                                addressLine2: account.address.addressLine2,
                                addressLine3: account.address.addressLine3,
                                postcode: account.address.postcode,
                                city: account.address.city,
                                country: account.address.country
                            },
                            invoiceAddress: {
                                taxId: account.invoiceAddress?.taxId,
                                addressLine1: account.invoiceAddress?.addressLine1,
                                addressLine2: account.invoiceAddress?.addressLine2,
                                addressLine3: account.invoiceAddress?.addressLine3,
                                postcode: account.invoiceAddress?.postcode,
                                city: account.invoiceAddress?.city,
                                country: account.invoiceAddress?.country
                            },
                            phone: account.phone,
                            residency: account.residency
                        };

                        return this.props.setAccountState(accountData);
                    }
                }),
                catchError((error: any) => {
                    return of(this.alertManager.handleApiError(error))
                })
            ).subscribe();
        }
    }

    componentWillUnmount() {
        if (null !== this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    render() {
        if (!this.props.isAuthenticated) {
            return (<Redirect push to={'/auth/login'}/>)
        }

        const collapsed = this.state.isSidebarCollapsed ? 'collapsed' : '',
            username = this.props.username ? this.props.username : null,
            name = this.props.account ? `${this.props.account.firstName} ${this.props.account.lastName}` : username;

        return (
            <div className="dashboard-view">
                <NavBar onToggleNavigation={this.toggleNavigation}
                        hasUnreadMessages={this.props.hasUnreadMessages}
                        onLogout={this.onLogout}
                        isDropdownVisible={false}
                        isSearchVisible={false}
                        generalNotificationsDisplayed={false}
                        profileNotificationsDisplayed={false}
                        navMenu={this.navMenu}
                        username={name}/>
                <div className="content-main">
                    <Sidebar navigation={navigation}
                                collapseMenu={this.state.isSidebarCollapsed}/>

                    <div className={`main-container ${collapsed}`}>
                        <div className="content">

                            <Switch>
                                {/*<Route path="/dashboard" component={Dashboard} exact strict key="dashboard"/>*/}
                                <Route path="/dashboard/profile" component={Profile} exact strict key="profile"/>
                                <Route path="/dashboard/inquiries" component={InsuranceList} exact strict key="insuranceList"/>
                                <Route path="/dashboard/inquiries/:id" component={InsuranceView} exact key="insuranceView"/>
                                <Route path="/dashboard/consultations" component={Consultations} exact strict key="consultations"/>
                                <Route path="/dashboard/chats" render={() => <Chat
                                    saveMessage={(message: any) => sendMessageToOfflineAPI(this.props.authToken, message)}
                                    saveNotification={(delayedMessage: typeof NotificationMessage) => postDelayedMessageAPI(this.props.authToken, delayedMessage)}
                                    saveFile={(formData: FormData) => saveFile(this.props.authToken, formData)}
                                    downloadFile={(url: string) => downloadFile(this.props.authToken, url)}
                                    errorHandler={(e: any) => this.alertManager.handleApiError(e)}
                                    errors={this.props.errors}

                                    hasUnreadMessages={this.props.hasUnreadMessages}
                                    peerConnections={this.props.peerConnections}
                                    setSelectedChatRoomId={this.props.setSelectedChatRoomId}
                                    selectedChatRoomId={this.props.selectedChatRoomId}
                                    chatContactsList={this.props.allowedChannels}
                                    addMessage={this.props.addMessage}
                                    userName={this.props.username}
                                    userId={this.props.userId}
                                    showOnlineIndicator={false}
                                    isConnected={this.props.hasNodeConnection} />} exact strict key="chat"/>
                                <Route path="/dashboard/treatmentPlans" component={TreatmentPlannerList} exact strict key="treatmentPlans"/>
                                <Route path="/dashboard/treatmentPlans/:id" component={TreatmentPlannerView} exact key="treatmentPlannerView"/>
                                <Route component={() => (<Redirect to={`/dashboard/inquiries`}/>)}/>
                                <Route key="not-found" component={NotFound}/>
                            </Switch>

                        </div>
                    </div>
                </div>
                <Loader showLoader={this.props.showLoader}/>
                <Toast/>
            </div>
        );
    }

    toggleNavigation = (): void => {
        this.setState({isSidebarCollapsed: !this.state.isSidebarCollapsed});
    };

    private onLogout = () => {
        this.props.setAuthState(null, null, null, null, null, false);
        setTimeout(() => {
            window.location.href = '/auth/login';
        }, 0);
    };
}

export default connect(
    (state: RootState) => ({
        showLoader: showLoaderSelector(state),
        isAuthenticated: isAuthenticatedSelector(state),
        authToken: authTokenSelector(state),
        accountId: accountIdSelector(state),
        account: accountSelector(state),
        username: usernameSelector(state)
    }),
    {
        setAuthState,
        setAccountState
    }
)(withRouter(withChatContactsList(withChatConnection(PanelHost))));
