import React from "react";
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {
  accountLoadingSelector,
  accountSelector,
  CustomCard,
  Form,
  Translation,
  usernameSelector,
  updateAccountState
} from "common-web";
import {personalDataFormConfig} from "./personalDataFormConfig";

interface IPersonalDataConnectedProps {
    readonly account: any;
    readonly username: string;
    readonly isLoading: boolean;
    readonly updateAccountState: typeof updateAccountState;
}

interface IPersonalDataExternalProps {
}

interface IPersonalDataProps extends IPersonalDataConnectedProps,
    IPersonalDataExternalProps {
}

interface IPersonalDataState {
    isLoading: boolean;
    formConfig: any;
    value: any;
}

class PersonalData extends React.Component<IPersonalDataProps, IPersonalDataState> {
  constructor(props: IPersonalDataProps) {
    super(props);

    this.state = {
      isLoading: false,
      formConfig: personalDataFormConfig,
      value: null
    };
  }

  componentDidMount() {
    if (this.props.account) {
      this.updateFormFromState();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IPersonalDataProps>,
    prevState: Readonly<IPersonalDataState>,
    snapshot?: any
  ): void {
    if (this.props.account !== prevProps.account) {
      this.updateFormFromState();
    }

    if (this.props.isLoading !== prevProps.isLoading
      && !this.props.isLoading) {
      this.setState({isLoading: this.props.isLoading})
    }
  }

  render() {
    return (
      <CustomCard showLocalLoader={this.state.isLoading}>
        <CustomCard.Header>
          <Translation text={'setting.personalData.title'}/>
        </CustomCard.Header>
        <CustomCard.Body>
          <Form config={this.state.formConfig}
                submitForm={this.changePersonalData}
                value={this.state.value}
                controlName={'personalDataForm'}/>

        </CustomCard.Body>
      </CustomCard>
    );
  }

  private changePersonalData = (event: any, value: any, valid: boolean, touched: boolean): void => {
    if (!valid || !touched) {
      return;
    }
    this.setState({isLoading: true});

    const payload = {
      id: this.props.account.id,
      firstName: value.firstName,
      lastName: value.lastName,
      birthDate: value.birthDate,
      address: this.props.account.address,
      invoiceAddress: this.props.account.invoiceAddress,
      phone: this.props.account.phone,
      residency: value.residency
    };

    this.props.updateAccountState(payload);
  };

  private mapAccountToFormData(account: any): any {
    return {
      email: this.props.username,
      firstName: account?.firstName,
      lastName: account?.lastName,
      birthDate: account?.birthDate?.split('T')[0],
      residency: account?.residency
    };
  }

  updateFormFromState() {
    this.setState({
      value: this.mapAccountToFormData(this.props.account)
    })
  }
}

export default connect(
  (state: RootState) => ({
    account: accountSelector(state),
    username: usernameSelector(state),
    isLoading: accountLoadingSelector(state)
  }),
    {
    updateAccountState
  }
)(PersonalData);
