const pl = {
  axaAdditionalInformation: {
    heading: "Formulaż konfiguracyjny ubezpieczenia podróżnego",
    title: "Clinic Hunter Cover",
    subtitle: "medyczne ubezpieczenia podróżne",
    howItWorks: "Jak to działa",
    aboutUs: "O nas",
    terms: "Warunki",
    claims: "Roszczenia",
    faq: "faq",
  }
};

export default pl;
