import React from 'react';
import styles from "./styles.module.scss";


interface IConnectedSortingButtonsProps {}

interface IExternalSortingButtonsProps {
    sortDataDesc: (param: string) => void;
    sortDataAsc: (param: string) => void;
    sortingParam: string;
    isSortingAsc: boolean | null;
}

interface ISortingButtonsProps extends IConnectedSortingButtonsProps,
    IExternalSortingButtonsProps {}

interface ISortingButtonsState {}

class SortingButtons extends React.Component<ISortingButtonsProps, ISortingButtonsState> {

    render() {
        return (
            <React.Fragment>
                <button className={`${styles.sortBtn} ${this.props.isSortingAsc === false ? styles.active : ''}`}
                        onClick={() => this.props.sortDataDesc(this.props.sortingParam)}>
                    <span className="feather icon-arrow-down"/>
                </button>

                <button className={`${styles.sortBtn} ${this.props.isSortingAsc ? styles.active : ''}`}
                        onClick={() => this.props.sortDataAsc(this.props.sortingParam)}>
                    <span className="feather icon-arrow-up"/>
                </button>
            </React.Fragment>
        );
    }
}

export default SortingButtons;
