import {FormButtonType, FormControlType, IFormConfig, InputType, MultiSelectType, residencyCountryList, ValidationRules} from 'common-web';

export const personalDataFormConfig: typeof IFormConfig = {
    class: 'col-xl-12',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'email',
            class: 'row',
            controls: {
                email: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    // validationRules: [
                    //   { name: ValidationRules.IS_REQUIRED },
                    //   { name: ValidationRules.EMAIL },
                    // ],
                    placeholder: 'Email',
                    label: 'Email',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12',
                    disabled: true,
                }
            },
        },
        {
            controlType: 'group',
            key: 'name',
            class: 'row',
            controls: {
                firstName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        // {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'First name',
                    label: 'First name',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6',
                },
                lastName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        // {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'Last name',
                    label: 'Last name',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6',
                }
            },
        },
        {
            controlType: 'group',
            key: 'birthdate_residency',
            class: 'row',
            controls: {
                birthDate: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.DATE,
                    validationRules: [
                        // {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'Your birth date',
                    label: 'Your birth date',
                    type: InputType.DATE,
                    hostClass: 'col-xl-6',
                    showMonthDropdown: true,
                    showYearDropdown: true,
                },
                residency: {
                    controlType: 'control',
                    defaultValue: '',
                    hostClass: 'col-xl-6',
                    placeholder: 'Select country',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label: 'What is your residency?',
                    validationRules: [
                        // {name: ValidationRules.IS_REQUIRED},
                    ],
                    multiselectOptions: residencyCountryList,
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return residencyCountryList
                            .filter((country: any) => country.value === data)
                            .map((country: any) => ({
                                value: country.value,
                                label: country.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }

                        return data;
                    },
                }
            }
        },
        {
            controlType: 'group',
            key: 'personal_submit',
            class: 'row',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'col-xl-12',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-3 pr-0',
                    defaultValue: null,
                    btnText: 'Save',
                    defaultStyles: 'btn btn-primary'
                },
            },
        },
    ]
};
