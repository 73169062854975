import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {list, reset} from '../../actions/inquiry/list';
import {RootState} from '../../store/reducers';
import {
    authTokenSelector,
    CustomCard,
    DateConverter,
    CustomPagination,
    RestQueryParams,
    Translation,
    formatDateToString
} from 'common-web';
import {
    inquiryListErrorSelector,
    inquiryListEventSourceSelector,
    inquiryListLoadingSelector,
    retrievedListSelector
} from '../../store/selectors/inquiryListSelectors';
import {DateRangePicker} from 'rsuite';
import {isNullOrUndefined} from '../../utils/runtimeUtils';
import {withTranslation, WithTranslation} from 'react-i18next';
import InsuranceStatusBadge from './InsuranceStatusBadge';
import moment from 'moment';
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {IAlertManagerService} from "../../service/alertManagerService";
import EditInsuranceDate from "./EditInsuranceDate";

export enum InsuranceStatus {
    PAID = 'status_paid',
    ACTIVE = 'status_active',
    EXPIRED = 'status_expired',
    DRAFT = 'status_draft',
    MODIFIED = 'status_modified',
}

const datepickerRanges: any[] = [
    {
        label: 'Previous Month',
        value: [
            new Date(moment().subtract(1,'months').startOf('month').format()),
            new Date(moment().subtract(1,'months').endOf('month').format())
        ]
    },
    {
        label: 'last7Days',
        value: [
            new Date(moment().subtract(7, 'days').format()),
            new Date(moment().endOf('day').format())]
    },
    {
        label: 'today',
        value: [
            new Date(moment().startOf('day').format()),
            new Date(moment().endOf('day').format())
        ]
    },
    {
        label: 'Current Month',
        value: [
            new Date(moment().startOf('month').format()),
            new Date(moment().endOf('month').format())
        ]
    },
];

interface IConnectedInsuranceListProps {
    readonly retrieved: any;
    readonly loading: boolean;
    readonly error: string;
    readonly eventSource: EventSource;
    readonly list: any;
    readonly reset: any;
    readonly authToken: string;
}

interface IExternalInsuranceListProps {
}

interface IInsuranceListProps extends IConnectedInsuranceListProps,
    IExternalInsuranceListProps,
    RouteComponentProps,
    WithTranslation {}

interface IListState {
    startDate: Date | null;
    isEditInsuranceDateModalShown: boolean;
    selectedInquiry: {[key: string]: any} | null;
}

class InsuranceList extends React.Component<IInsuranceListProps, IListState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IInsuranceListProps) {
        super(props);

        this.state = {
            startDate: null,
            isEditInsuranceDateModalShown: false,
            selectedInquiry: null
        };

        fixInjectedProperties(this);
    }

    componentDidUpdate(
        prevProps: Readonly<IInsuranceListProps>,
        prevState: Readonly<{}>,
        snapshot?: any
    ): void {
        if (this.props.error !== prevProps.error) {
            this.alertManager.handleApiError(this.props.error);
        }
    }

    componentWillUnmount() {
        this.props.reset(this.props.eventSource);
    }

    render() {
        const {t} = this.props;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title">
                                <Translation text={`inquiries.inquiryList.title`}/>
                            </div>
                            <div className="action-container">
                                <a href={`${process.env.REACT_APP_AXA_INSURANCE_URL}`} className="btn btn-theme">
                                    <Translation text={`inquiries.inquiryList.newInsurance`}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="filter-container">
                            {/*<input type="text"*/}
                                   {/*placeholder={t(`inquiries.inquiryList.table.searchPlaceholder`)}*/}
                                   {/*className="form-control"*/}
                                   {/*onChange={this.handleChange}/>*/}

                            <div className="group-control">
                                <DateRangePicker placeholder={t(`inquiries.inquiryList.table.datePickerPlaceholder`)}
                                                 onChange={this.handleDateChange}
                                                 ranges={datepickerRanges}
                                                 // placement="autoVertical"
                                                 renderValue={(value) => {
                                                     return `${formatDateToString(value[0])} - ${formatDateToString(value[1])}`;
                                                 }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <CustomCard showLocalLoader={this.props.loading}>
                            <CustomCard.Body>
                                { this.renderInsuranceList() }

                                <CustomPagination retrieved={this.props.retrieved}
                                                  basePath="dashboard"
                                                  path="inquiries"
                                                  provider={this.getInsurancesList}
                                />
                            </CustomCard.Body>
                        </CustomCard>

                    </div>
                </div>

                {this.state.isEditInsuranceDateModalShown ?
                    <EditInsuranceDate isModalShown={this.state.isEditInsuranceDateModalShown}
                                       authToken={this.props.authToken}
                                       toggleModal={this.toggleEditInquiryDate}
                                       inquiry={this.state.selectedInquiry}
                                       getInquiryList={this.getInsurancesList}/> : null}
            </React.Fragment>
        );
    }

    private renderInsuranceList = () => {
        const retrieved = this.props.retrieved;
        if (!retrieved || !retrieved['hydra:member'] || !Array.isArray(retrieved['hydra:member'])) {
            return (<p>There is no data available</p>);
        }

        const list = this.props.retrieved['hydra:member']; //.sort(this.sortMethod);

        return (
            <table className="data-table">

                { this.renderTableHeader() }

                { this.renderTableBody(list) }

            </table>
        )
    };

    private renderTableHeader() {
        return (
            <thead>
                <tr>
                    <th><Translation text={`inquiries.inquiryList.table.created`}/></th>
                    <th><Translation text={`inquiries.inquiryList.table.number`}/></th>
                    <th><Translation text={`inquiries.inquiryList.table.treatment`}/></th>
                    <th><Translation text={`inquiries.inquiryList.table.startDate`}/></th>
                    <th><Translation text={`inquiries.inquiryList.table.endDate`}/></th>
                    <th className="text-center"><Translation text={`inquiries.inquiryList.table.insuranceStatus`}/></th>
                    <th className="text-center"><Translation text={`inquiries.inquiryList.table.paymentStatus`}/></th>
                    <th/>
                </tr>
            </thead>
        )
    }

    private renderTableBody(list: any = null) {
        const rows: any[] = [];
        list.map((item: any) => {
            return rows.push((
                <tr key={item['@id']}>
                    <td className="align-middle"><DateConverter date={item.createdAt}/></td>
                    <td className="align-middle"> { this.getInsuraneNumber(item) }</td>
                    <td className="align-middle">{item.treatmentCategory.name}</td>
                    <td className="align-middle"><DateConverter date={item.from}/></td>
                    <td className="align-middle"><DateConverter date={item.to}/></td>
                    <td className="text-center align-middle">{ this.renderInsuranceStatus(item) }</td>
                    <td className="text-center align-middle">{ this.renderBadge(item) }</td>
                    <td className="text-right align-middle">{ this.renderActions(item) }</td>
                </tr>
            ))
        });

        return (<tbody>{ rows }</tbody>
        );
    }

    private renderBadge(item: any) {
        const inquiry = item.acceptedOffer ? item : null;
        return (<InsuranceStatusBadge inquiry={inquiry}/>)
    }

    private renderInsuranceStatus(item: any) {
        const status = item.offers[0].status;
        if (status) {
            return (
                <div className="badge-wrapper">
                    <span className={`${"badge-text"} ${status}`}>
                        <Translation text={`inquiries.inquiryList.table.status.${status}`}/>
                    </span>
                </div>
            );
        }

        return <p>---</p>;
    }

    private renderActions(item: any) {
        const {t} = this.props,
            resetTodayDate = new Date().setHours(0, 0, 0),
            now = new Date(resetTodayDate),
            offerDetails = item.offers[0],
            isOfferModified = offerDetails?.status === InsuranceStatus.MODIFIED,
            isStartDateAdvanced = this.isStartDateEditable(item.from);
        let isPaymentExpired = false;

        if (item.acceptedOffer) {
            const paymentExpiration = new Date(item.acceptedOffer?.payments[0]?.expiresAt);
            isPaymentExpired = now.getTime() > paymentExpiration.getTime();
        }

        return (
            <React.Fragment>
                <button className="btn btn-action mr-2"
                        disabled={!item.offers[0]?.paidAt}
                        onClick={() => this.goToInquiryView(item['id'])}>
                    <abbr title={t(`inquiries.inquiryList.table.buttons.showExpanded`)}>
                        <span className="feather icon-search" aria-hidden="true"/>
                    </abbr>
                    <span className="sr-only">
                        <Translation text={`inquiries.inquiryList.table.buttons.show`}/>
                    </span>
                </button>
                <button className="btn btn-action mr-2"
                        disabled={item.offers[0]?.paidAt || isPaymentExpired}
                        onClick={() => this.goToInquiryForm(item['id'])}>
                    <abbr title={t(`inquiries.inquiryList.table.buttons.editExpanded`)}>
                        <span className="feather icon-edit-2" aria-hidden="true"/>
                    </abbr>
                    <span className="sr-only">
                        <Translation text={`inquiries.inquiryList.table.buttons.edit`}/>
                    </span>
                </button>

                <button className="btn btn-action mr-2"
                        type="button"
                        disabled={isOfferModified || !isStartDateAdvanced}
                        onClick={() => this.toggleEditInquiryDate(item)}>
                    <abbr title={t(`inquiries.inquiryList.table.buttons.editDate`)}>
                        <span className="feather icon-calendar custom-icon" aria-hidden="true"/>
                    </abbr>
                    <span className="sr-only">
                        <Translation text={`inquiries.inquiryList.table.buttons.edit`}/>
                    </span>
                </button>
                <button className="btn btn-action"
                        disabled={!item.offers[0]?.paidAt}
                        onClick={() => this.downloadInquiry(item['id'])}>
                    <abbr title={t(`inquiries.inquiryList.table.buttons.downloadExpanded`)}>
                        <span className="feather icon-download" aria-hidden="true"/>
                    </abbr>
                    <span className="sr-only">
                        <Translation text={`inquiries.inquiryList.table.buttons.download`}/>
                    </span>
                </button>
            </React.Fragment>
        )
    }

    private goToInquiryForm(id: string): void {
        window.location.href=`${process.env.REACT_APP_AXA_INSURANCE_URL}/inquiry/treatment/${id}`;
    }

    private goToInquiryView(id: string): void {
        this.props.history.push(`/dashboard/inquiries/${id}`);
    }

    private sortMethod(a: any, b: any): number {
        const aDate = new Date(a.createdAt),
            bDate = new Date(b.createdAt),
            aTime = aDate.getTime(),
            bTime = bDate.getTime();

        return aTime >= bTime ? -1 : 1;
    }

    private getInsuraneNumber(item: any): string {
        return item.acceptedOffer ? item.acceptedOffer.reference : '-';
    }

    private getInsurancesList = (searchParams: typeof RestQueryParams) => {
      searchParams = searchParams.add('paid_at_sorting', 'DESC');
      this.props.list(`inquiries${searchParams.prepareQuery()}`, this.props.authToken);
    };

    private handleDateChange = (e: any) => {
        if (!e.length || isNullOrUndefined(e)) {
            return;
        }

        const startDate = new Date(e[0].getTime() - (e[0].getTimezoneOffset() * 60000)).toISOString().split('T')[0];
        const endDate = new Date(e[1].getTime() - (e[1].getTimezoneOffset() * 60000)).toISOString().split('T')[0];
        this.props.list(
            `inquiries?page=1&from[after]=${startDate}&from[strictly_before]=${endDate}`,
            this.props.authToken,
        );
    };


    private downloadInquiry = (inquiryId: string) => {
        window.open(`${process.env.REACT_APP_AUTH_API_URL}/inquiry/${inquiryId}/to-pdf`, '_blank');
    };

    private toggleEditInquiryDate = (inquiry?: {[key: string]: any} | null) => {
        this.setState({isEditInsuranceDateModalShown: !this.state.isEditInsuranceDateModalShown, selectedInquiry: inquiry ? inquiry : null});
    }

    private isStartDateEditable = (date: string) => {
        const msInOneDay = 24 * 60 * 60 * 1000,
            now = new Date(),
            diffInMs = new Date(date).getTime() - now.getTime();
        return diffInMs >= msInOneDay;
    }
}

export default withTranslation()(connect(
    (state: RootState) => ({
        retrieved: retrievedListSelector(state),
        loading: inquiryListLoadingSelector(state),
        error: inquiryListErrorSelector(state),
        eventSource: inquiryListEventSourceSelector(state),
        authToken: authTokenSelector(state)
    }),
    {
        list,
        reset,
    }
)(withRouter(InsuranceList)));
