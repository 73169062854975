import { Observable } from "rxjs";
import { RestQueryParams } from "common-web";
import {patientAPI} from "./provider/patientAPI";

export function cancelConsultationAPI(
  consultationId: string,
  accessToken: string
): Observable<any> {
  return patientAPI.put(
    `online_consultation/${consultationId}/cancel`,
    {},
    new RestQueryParams(),
    {
      Authorization: `Bearer ${accessToken}`
    }
  );
}
