import React from "react";
import {Translation} from "common-web";
import PersonalData from "./PersonalData";
import AddressData from "./AddressData";
import ChangePassword from "./ChangePassword";

interface IConnectedProfileProps {
}

interface IExternalProfileProps {
}

interface IProfileProps extends
    IConnectedProfileProps,
    IExternalProfileProps {
}


class Profile extends React.Component<IProfileProps> {

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title">
                                <Translation text={'profile.title'}/>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row pt-4">
                    <div className="col-xl-6 col-md-6 col-sm-12">
                        <PersonalData/>
                        <AddressData/>
                    </div>

                    <div className="col-xl-6 col-md-6 col-sm-12">
                        <ChangePassword/>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Profile;
