import React from 'react';
import {Translation} from "common-web";
import {withTranslation, WithTranslation} from 'react-i18next';

export enum BadgeStatus {
  DRAFT = 'status_draft',
  DRAFT_EXPIRED = 'draft_expired',
  ACTIVE = 'status_active',
  EXPIRED = 'status_expired',
  PAID = 'status_paid'
}

interface IInsuranceStatusBadgeProps extends WithTranslation {
  inquiry: any;
}

class InsuranceStatusBadge extends React.Component<IInsuranceStatusBadgeProps> {

  render() {
      let status = BadgeStatus.DRAFT;
      if (this.props.inquiry) {
         status = this.props.inquiry.acceptedOffer.status;

         const resetTodayDate = new Date().setHours(0, 0, 0),
            now = new Date(resetTodayDate);

         if (this.props.inquiry.acceptedOffer) {
             const paymentExpiration = new Date(this.props.inquiry.acceptedOffer.payments[0]?.expiresAt);

             if (status === BadgeStatus.DRAFT && now.getTime() > paymentExpiration.getTime()) {
                 status = BadgeStatus.DRAFT_EXPIRED;
             }
         }
      }

    const {t} = this.props;
    return (
      <React.Fragment>
        <div className="badge-wrapper">
            <span className={`${"badge-text"} ${status}`}>
                <abbr className="badge-tooltip" title={t(`inquiries.inquiryList.table.badges.${status}`)}>
                    <Translation text={`inquiries.inquiryList.table.badges.${status}`}/>
                </abbr>
            </span>
        </div>
      </React.Fragment>
    );
  }
}


export default withTranslation()(InsuranceStatusBadge);
