import React from 'react';
import {connect} from 'react-redux';
import {WithTranslation, withTranslation} from "react-i18next";
import {Link, RouteComponentProps} from 'react-router-dom';
import {reset, retrieve} from '../../../actions/inquiry/show';
import {RootState} from "../../../store/reducers";
import {authTokenSelector, ClinicHunterDocuments, CustomCard, DateConverter, Translation} from "common-web";
import {
    inquiryItemErrorSelector,
    inquiryItemEventSourceSelector,
    inquiryItemLoadingSelector,
    retrievedInquiryItemSelector
} from "../../../store/selectors/inquiryItemSelectors";

interface IConnectedShowProps {
    readonly retrieved?: any;
    readonly loading: boolean;
    readonly error?: string;
    readonly eventSource?: EventSource;
    readonly authToken: string;
    readonly retrieve: typeof retrieve;
    readonly reset: any;
}

interface IExternalShowProps {
}

interface IShowProps extends IConnectedShowProps,
    IExternalShowProps,
    RouteComponentProps,
    WithTranslation {
}

interface IShowState {
    mainInquirySubject: IInquirySubject | null;
}

export interface IInquirySubject {
    firstName: string;
    lastName: string;
    birthDate: string;
    main?: boolean;
}

class Index extends React.Component<IShowProps, IShowState> {
    constructor(props: IShowProps) {
        super(props);

        this.state = {
            mainInquirySubject: null
        }
    }

    componentDidMount() {
        const slug = this.props.match.params;
        this.props.retrieve(`inquiries/${(slug as any).id}`, this.props.authToken);
    }

    componentWillUnmount() {
        if (this.props.eventSource) {
            this.props.reset(this.props.eventSource);
        }
    }

    render() {
        const item = this.props.retrieved;
        const mainInquirySubject = item?.inquirySubjects.find((subject: IInquirySubject) => subject.main === true),
            additionalCompanion = item?.inquirySubjects.find((subject: IInquirySubject) => subject.main === false);
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title">
                                <Translation text={`inquiries.inquiryPage.title`}/>
                                {this.renderTitle(mainInquirySubject)}
                            </div>
                            <div className="action-container">
                                <div onClick={() => this.props.history.goBack()} className="btn btn-no-outline">
                                    <span className="feather icon-chevron-left"/>
                                    <Translation text={`inquiries.inquiryList.table.buttons.back`}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <CustomCard showLocalLoader={this.props.loading}>
                            <CustomCard.Header>
                                Insurance Details
                            </CustomCard.Header>
                            <CustomCard.Body>
                                <div className="mr-4 ml-4">
                                    {this.props.error && (
                                        <div className="alert alert-danger" role="alert">
                                            <span className="feather icon-alert-circle" aria-hidden="true"/>{' '}
                                            {this.props.error}
                                        </div>
                                    )}

                                    {item && (
                                        <React.Fragment>
                                            {this.renderMainInsuredTable(mainInquirySubject, item)}

                                            {additionalCompanion ?
                                                (this.renderAdditionalCompanionTable(additionalCompanion, item)) : null
                                            }
                                        </React.Fragment>
                                    )}
                                </div>
                            </CustomCard.Body>
                        </CustomCard>
                    </div>

                    <div className="col-xl-6">
                        <CustomCard showLocalLoader={this.props.loading}>
                            <CustomCard.Header>
                                Insurance Documents
                            </CustomCard.Header>
                            <CustomCard.Body>
                                {item &&
                                (
                                    this.renderInsuranceDocsTable(item)
                                )}
                            </CustomCard.Body>
                        </CustomCard>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderTitle(mainInquirySubject: any = null) {
        if (!mainInquirySubject) {
            return (<span>No main patient selected</span>);
        }
        return (<span> {mainInquirySubject.firstName} {mainInquirySubject.lastName}</span>)
    }

    private renderMainInsuredTable(mainInquirySubject: any, item: any) {
        return (
            <table className="data-table no-hover">
                <tbody>
                <tr>
                    <td scope="row"><Translation text={`inquiries.inquiryPage.insuredPerson`}/></td>
                    <td>
                        {`${mainInquirySubject.firstName} ${mainInquirySubject.lastName}`}
                    </td>
                </tr>
                <tr>
                    <td scope="row"><Translation text={`inquiries.inquiryPage.treatmentType`}/></td>
                    <td>
                        {item.treatmentCategory.name}
                    </td>
                </tr>

                <tr>
                    <td scope="row"><Translation text={`inquiries.inquiryPage.startDate`}/></td>
                    <td><DateConverter date={item.from}/></td>
                </tr>

                <tr>
                    <td scope="row"><Translation text={`inquiries.inquiryPage.endDate`}/></td>
                    <td><DateConverter date={item.to}/></td>
                </tr>

                <tr>
                    <td scope="row">
                        <Translation text={`inquiries.inquiryPage.price`}/>
                    </td>
                    <td>{item.offers[0].fee ?
                        <span>€ {item.offers[0].fee.amount}</span> :
                        // <span>{item.offers[0].fee.amount} {item.offers[0].fee.currency.code}</span> :
                        <Translation text={`inquiries.inquiryPage.noPrice`}/>}
                    </td>
                </tr>
                </tbody>
            </table>
        )
    }

    private renderAdditionalCompanionTable(additionalCompanion: any, item: any) {
        return (
            <table className="data-table no-hover">
                <tbody>
                <tr>
                    <td scope="row">
                        <Translation text={`inquiries.inquiryPage.patientCompanion`}/>
                    </td>
                    <td>{additionalCompanion.firstName} {additionalCompanion.lastName}</td>
                </tr>

                <tr>
                    <td scope="row"><Translation text={`inquiries.inquiryPage.startDate`}/></td>
                    <td><DateConverter date={item.from}/></td>
                </tr>

                <tr>
                    <td scope="row"><Translation text={`inquiries.inquiryPage.endDate`}/></td>
                    <td><DateConverter date={item.to}/></td>
                </tr>

                <tr>
                    <td scope="row">
                        <Translation text={`inquiries.inquiryPage.price`}/>
                    </td>
                    <td>{item.offers[0].fee ?
                        <span>€ {item.offers[0].fee.amount}</span> :
                        // <span>{item.offers[0].fee.amount} {item.offers[0].fee.currency.code}</span> :
                        <Translation text={`inquiries.inquiryPage.noPrice`}/>}
                    </td>
                </tr>
                </tbody>
            </table>
        );
    }

    private renderInsuranceDocsTable(item: any) {
        return (
            <table className="data-table no-hover">
                <tbody>
                <tr>
                    <td scope="row">
                        <Translation text={`inquiries.inquiryPage.insuranceAgreement`}/>
                    </td>
                    <td>
                        <a href={`${process.env.REACT_APP_AUTH_API_URL}/inquiry/${this.props.retrieved.id}/to-pdf`}
                           target={'_blank'}>Policy Document</a>
                    </td>
                </tr>

                <tr>
                    <td scope="row"><Translation text={`inquiries.inquiryPage.otherDocuments`}/></td>
                    <td className="additional-documents">
                        <ol>
                            {this.renderDocListItems(item)}
                        </ol>
                    </td>
                </tr>
                </tbody>
            </table>
        )
    }

    private renderDocListItems(item: any) {
        return item.insuranceDocs.map((doc: any, i: number) => {
            return (
                <li key={i}>
                    <a href={doc.contentUrl}
                       download>
                        {this.renderDocName(doc['contentUrl'])}
                    </a>
                </li>
            )
        });
    }

    private renderDocName(docId: string) {
        const {t} = this.props;
        let translationKey = '';
        if (docId.includes(ClinicHunterDocuments.PersonalDataProcessingBooklet)) {
            translationKey = `inquiries.inquiryPage.documentTypes.personalDataProcessingBooklet`
        } else if (docId.includes(ClinicHunterDocuments.InsuranceContractRegulations)) {
            translationKey = 'inquiries.inquiryPage.documentTypes.insuranceContractRegulations'
        } else if (docId.includes(ClinicHunterDocuments.SpecialTermsAndConditions)) {
            translationKey = 'inquiries.inquiryPage.documentTypes.specialTermsAndConditions'
        } else {
            translationKey = 'inquiries.inquiryPage.documentTypes.otherDocument'
        }
        return t(translationKey);
    }
}


export default withTranslation()(connect(
    (state: RootState) => ({
        retrieved: retrievedInquiryItemSelector(state),
        error: inquiryItemErrorSelector(state),
        loading: inquiryItemLoadingSelector(state),
        eventSource: inquiryItemEventSourceSelector(state),
        authToken: authTokenSelector(state)
    }),
    {
        retrieve,
        reset
    }
)(Index));
