import { createSlice } from "redux-starter-kit";
import { LanguageLocale } from "../../constants/locales";

export interface ISagaState {
  activeLanguage: LanguageLocale;
  showLoader: boolean;
}

export interface IChangeLanguage {
  readonly payload: {
    activeLanguage: LanguageLocale;
  };
}

export interface IChangeShowLoader {
  readonly payload: {
    showLoader: boolean;
  };
}

const initialState: ISagaState = {
  activeLanguage: LanguageLocale.EN,
  showLoader: false
};

const sagaSlice = createSlice({
  slice: "saga",
  initialState: initialState,
  reducers: {
    changeActiveLanguage: {
      reducer: (state: ISagaState, action: IChangeLanguage) => {
        return {
          activeLanguage: action.payload.activeLanguage,
          showLoader: state.showLoader
        }
      },
      prepare(activeLanguage: LanguageLocale) {
        return {
          payload: { activeLanguage: activeLanguage }
        };
      }
    },
    changeShowLoader: {
      reducer: (state: ISagaState, action: IChangeShowLoader) => {
        return {
          activeLanguage: state.activeLanguage,
          showLoader: action.payload.showLoader,
        };
      },
      prepare(showLoader: boolean) {
        return {
          payload: { showLoader: showLoader }
        };
      }
    },
  }
});

export const {
  changeActiveLanguage,
  changeShowLoader
} = sagaSlice.actions;

export default sagaSlice.reducer;
