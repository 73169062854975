const en = {
    chat: {
        title: "Chat with Clinics",
        noNodeConnection: "Chat server is unavailable. Please check again later",
        fileSent: "Sent file",
        noMessages: "There are no messages yet.",
        fileSize: "File size",
        fileIsReadyToSent: 'File is ready to be sent!',
        inputPlaceholder: 'Type something...',
        connectionProblems: 'Something went wrong. Chat is currently unavailable. Check your internet connection or try to refresh page.',
        noUserListProvided: 'No users list was provided',
        online: 'online',
        connectionFailed: 'Unable to establish direct connection',
        connecting: 'Connecting to chat...',
        userNamePlaceholder: 'Unknown username',
        noConnectionReasons: 'Possible reasons are listed bellow: ',
        not_authorized: 'Wrong token provided. Try login again',
        'websocket error': 'Some other reasons are possible',
        already_online: `Some other user logged onto this account. If it's you, please logout on other devices`,
        no_token: 'No token provided. Try login again',
        expired_token: 'Expired token provided. Try login again',
    },
    formValidation: {
        errors: {
            minLength: "The input is too short",
            isEmailValid: "Provide a valid email address",
            isRequired: "The field is required",
            isTrue: "Must be checked",
            isPasswordSame: "Passwords not match",
            isStartDateValid: "Date must be after current day",
            isStartDateGreaterOrEvenValid: "Date must be at or after current day",
            isEndDateValid: "Date of return must be after departure",
            sameEmail: 'The emails should be the same',
            samePassword: 'The passwords should be the same'
        }
    },
    setting: {
        title: "Settings",
        addressData: {
            title: "Address Information"
        },
        changePassword: {
            title: "Change Password"
        },
        treatmentCategories: {
            title: "Treatment Categories"
        },
        invoiceData: {
            title: "Invoice Data"
        },
        personalData: {
            title: "Personal Data"
        },
        clinicLogo: {
            title: "Clinic Logo",
            placeholder: "Click or Drag files to this area to upload"
        }
    },
    profile: {
        title: "Profile",
        clinicDetails: {
            title: "Clinic Details"
        },
        clinicPhotos: {
            title: "Clinic Photos"
        },
        doctors: {
            title: "Doctors"
        },
        generalInformation: {
            title: "General Information"
        },
        treatmentCategories: {
            title: "Treatment Categories"
        }
    },
    inquiries: {
        inquiryPage: {
            title: 'Insurance of:',
            loader: 'Loading...',
            insuredPerson: 'Insured person:',
            id: 'Insurance Id:',
            patientCompanion: "Companion's name:",
            treatmentType: 'Treatment type:',
            clinic: 'Clinic:',
            noClinic: 'No clinic chosen',
            paid: 'Paid:',
            notPaid: 'Not paid',
            startDate: 'From:',
            endDate: 'To:',
            price: 'Price:',
            noPrice: 'No price set',
            insuranceAgreement: 'Insurance Agreement:',
            otherDocuments: 'Other docs:',
            documentTypes: {
                personalDataProcessingBooklet: "Information Brochure Concerning Personal Data Process",
                insuranceContractRegulations: "The Regulations for Concluding Distance Contracts",
                specialTermsAndConditions: "Special Terms and Conditions of Travel Insurance for ClinicHunter clients",
                otherDocument: "Other Document"
            }
        },
        inquiryList: {
            title: 'My Insurance Policies',
            noData: 'There is no data available',
            newInsurance: 'New Insurance',
            table: {
                created: 'Created',
                number: 'Number',
                treatment: 'Insurance treatment',
                startDate: 'Start',
                endDate: 'End',
                paymentStatus: 'Payment status',
                insuranceStatus: 'Insurance status',
                price: 'Price',
                actions: 'Actions',
                edit: 'Edit',
                searchPlaceholder: 'Enter insurance number or insured name',
                datePickerPlaceholder: 'Select Date Range',
                buttons: {
                    show: 'Show',
                    showExpanded: 'Click here to show details about Inquiry',
                    edit: 'Edit',
                    editExpanded: 'Click here to edit your Inquiry',
                    editDate: 'Click here to edit Inquiry date',
                    download: 'Download',
                    downloadExpanded: 'Click here to download your Inquiry',
                    pay: 'Pay',
                    payExpanded: 'Click here to pay for your Inquiry',
                    cancel: 'Cancel',
                    cancelExpanded: 'Click here to cancel the Inquiry',
                    first: 'First',
                    previous: 'Previous',
                    next: 'Next',
                    last: 'Last',
                    back: 'Back',
                },
                badges: {
                    draft: 'Draft',
                    paid: 'Paid',
                    expired: 'Expired',
                    active: 'Active',
                    draft_expired: 'Draft expired',
                    status_draft: 'Draft',
                    status_paid: 'Paid',
                    status_draftExpanded: 'Your inquiry is currently draft, finish all steps to change status.',
                    status_expired: 'Expired',
                    status_expiredExpanded: 'Expiration date of your inquiry passed. It is now expired.',
                    status_active: 'Active',
                    status_activeExpanded: 'Your Inquiry is active',
                    status_no_payment: 'No payments',
                    status_no_payment_expanded: 'There is no payments in this Inquiry',
                    status_modified: 'Cancelled',
                    verification: 'In verification',
                    verificationExpanded: 'Your payment is waiting to be verified',
                    payment_status_not_paid: 'Not paid',
                    payment_status_not_paid_expanded: `Your Inquiry is filled but it's not paid. Make a payment to finish process.`,
                    payment_status_paid: 'Paid',
                    payment_status_paid_expanded: 'Your payment is done',

                    posting: 'Posting',
                    postingExpanded: 'Your payment awaits being posted.'
                },
                status: {
                    status_paid: "paid",
                    status_active: "active",
                    status_expired: "expired",
                    status_draft: "draft",
                    status_modified: "cancelled",
                },
            },
            editDateForm: {
                title: 'Edit Inquiry Details',
                labels: {
                    insuranceStartDate: 'Insurance start date',
                },
                placeholders: {
                    insuranceStartDate: 'Insurance start date',
                },
                dateChangeSuccess: 'Inquiry date has been successfully changed',
            }
        },
        inquiryView: {

        }
    },
    consultations: {
        consultationsList: {
            title: 'My Consultations',
            newConsultation: 'New consultation',
            noData: 'There is no data available',
            table: {
                date: 'Date',
                clinicName: 'Clinic name',
                consultationName: 'Consultation name',
                status: 'Status',
                actions: 'Actions',
                searchPlaceholder: 'Enter consultation name',
                datePickerPlaceholder: 'Select Date Range',
                rescheduleConsultation: 'Reschedule',
                cancelConsultation: 'Cancel Consultation',
                openConsultation: 'Open',
                cancelModalTitle: 'Are you sure you want to cancel your consultation?'
            },
            statuses: {
                scheduled: 'Scheduled',
                draft: 'Draft',
                cancelled: 'Canceled',
                succeeded: 'Succeeded',
                notStarted: 'Not Started',
                started: 'Started'
            }
        }
    },
    treatmentPlanner: {
        title: 'Treatment Planner',
        table: {
            treatmentType: 'Treatment Type',
            status: 'Status'
        },
        calendarTitle: 'Treatment Calendar',
        noDataAvailable: 'There is no data available',
        noTreatmentPointsAvailable: 'There is no treatment available for selected date',
        selectDate: 'Pick a date to see Events',
        statuses: {
            active: "Active",
            draft: "Draft",
            inactive: "Inactive",
            invited: "Invited",
            expired: "Expired"
        },
        eventType: {
            aftercare: 'Aftercare',
            travelPlan: 'Travel plan'
        }
    },
    modal: {
        confirm: "Confirm"
    },
    button: {
        yes: 'Yes',
        no: 'No',
        back: 'Back'
    }

};

export default en;
