import React from 'react';
import './App.scss';
import {Redirect, Route, RouteComponentProps, Switch, withRouter} from 'react-router-dom';
import {UserRole, AuthType, AuthPanel, PrivateRoute} from "common-web";
import PanelHost from './components/PanelHost';
import {registrationFormConfig} from './routes/registrationFormConfig';

interface IAppProps extends RouteComponentProps {
}

class App extends React.Component<IAppProps> {

    render() {
        return (
            <Switch>
                <PrivateRoute path="/dashboard/" component={PanelHost} key="dashboard" />,

                <Route path="/auth/create-from-invitation" component={() =>  <AuthPanel userRole={UserRole.INVITATION} authType={AuthType.CREATE_FROM_INVITATION}  envData={process.env}/>} exact key="create-from-invitation"/>,
                <Route path="/auth/login" component={() => <AuthPanel userRole={UserRole.PATIENT} authType={AuthType.LOGIN}  envData={process.env}/>} exact key="login"/>,
                <Route path="/auth/register"
                       component={() =>  <AuthPanel userRole={UserRole.PATIENT} authType={AuthType.REGISTER} formConfig={registrationFormConfig}  envData={process.env}/>}
                       exact key="register"/>,
                <Route path="/auth/reset-password"
                       component={() =>  <AuthPanel userRole={UserRole.PATIENT} authType={AuthType.RESET_PASSWORD}  envData={process.env}/>} exact key="reset-password"/>,
                <Route path="/auth/new-password/:slug" component={() =>  <AuthPanel userRole={UserRole.PATIENT} authType={AuthType.NEW_PASSWORD}  envData={process.env}/>} exact key="new-password"/>,
                <Route path="/auth/accept-invitation" component={() =>  <AuthPanel userRole={UserRole.INVITATION} authType={AuthType.ACCEPT_INVITATION}  envData={process.env}/>} exact key="accept-invitation"/>,
                <Route component={() => (<Redirect to={'/dashboard'}/>)}/>,

            </Switch>
        );
    }
}

export default withRouter(App);
