import { createSelector } from "redux-starter-kit";
import { RootState } from "../reducers";
import { ISagaState } from "../reducers/sagaSlice";

export const selectSaga = (state: RootState): ISagaState => {
    return state.saga;
};

export const activeLanguageSelector = createSelector(
    [selectSaga],
    (state: ISagaState) => state.activeLanguage
);

export const showLoaderSelector = createSelector(
    [selectSaga],
    (state: ISagaState) => state.showLoader
);
