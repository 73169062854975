import { RootState } from "../reducers";
import { createSelector } from "redux-starter-kit";

export interface ITreatmentPlansBaseState {
    loading: boolean;
    error: string;
}

export interface ITreatmentPlansListState extends ITreatmentPlansBaseState {
    retrieved: any; // model for aftercare
    eventSource: EventSource;
}

export const selectTreatmentPlansList = (state: RootState): ITreatmentPlansListState => {
    return state.treatmentPlans.list;
};

export const retrievedTreatmentPlansListSelector = createSelector(
    [selectTreatmentPlansList],
    (state: ITreatmentPlansListState) => state.retrieved
);

export const treatmentPlansListLoadingSelector = createSelector(
    [selectTreatmentPlansList],
    (state: ITreatmentPlansListState) => state.loading
);

export const treatmentPlansListErrorSelector = createSelector(
    [selectTreatmentPlansList],
    (state: ITreatmentPlansListState) => state.error
);

export const treatmentPlansListEventSourceSelector = createSelector(
    [selectTreatmentPlansList],
    (state: ITreatmentPlansListState) => state.eventSource
);
