import { RootState } from "../reducers";
import { createSelector } from "redux-starter-kit";

export interface IInquiryBaseState {
    loading: boolean;
    error: string;
}

export interface IConsultationListState extends IInquiryBaseState {
    retrieved: any; // model for inquiry
    eventSource: EventSource;
}

export const selectConsultationList = (state: RootState): IConsultationListState => {
    return state.consultation.list;
};

export const retrievedConsultationsListSelector = createSelector(
    [selectConsultationList],
    (state: IConsultationListState) => state.retrieved
);

export const consultationListLoadingSelector = createSelector(
    [selectConsultationList],
    (state: IConsultationListState) => state.loading
);

export const consultationListErrorSelector = createSelector(
    [selectConsultationList],
    (state: IConsultationListState) => state.error
);

export const consultationListEventSourceSelector = createSelector(
    [selectConsultationList],
    (state: IConsultationListState) => state.eventSource
);
