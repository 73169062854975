import {FormControlType, ValidationRules, IFormConfig} from "common-web";

export const calendarFormConfig: typeof IFormConfig = {
    controlType: 'group',
    class: '',
    dataAccessor: (data: any) => data, // data is not grouped
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'date',
            class: 'form-row',
            controls: {
                date: {
                    hostClass: 'col-100 month-calendar event-calendar',
                    controlType: 'control',
                    defaultValue: null,
                    placeholder: 'Choose date',
                    formControlType: FormControlType.EVENT_CALENDAR,
                    // label: "Choose date",
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                    ],
                }
            }
        },
    ]
};
