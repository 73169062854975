import {
    FormButtonType,
    FormControlType,
    IFormConfig,
    InputType,
    ValidationRules
} from 'common-web';

export const registrationFormConfig: typeof IFormConfig = {
    controlType: 'form',
    class: '',
    dataAccessor: (data: any) => data, // data is not grouped
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'registration_email',
            controlType: 'group',
            class: 'row register-form',
            controls: {
                email: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.IS_EMAIL, params: {}}
                    ],
                    placeholder: 'Email address',
                    isLabelHidden: true,
                    label: 'Email address',
                    type: InputType.EMAIL,
                    hostClass: 'col-xl-12'
                }
            }
        },
        {
            controlType: 'group',
            key: 'registration_password',
            class: 'row register-form',
            validationRules: [
                {
                    name: ValidationRules.ARE_FIELDS_SAME,
                    params: {
                        accessors: [
                            (value: any) => value.password,
                            (value: any) => value.newPassword
                        ]
                    }
                }
            ],
            controls: {
                password: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 4}}
                    ],
                    placeholder: 'Password',
                    isLabelHidden: true,
                    label: 'Password',
                    type: InputType.PASSWORD,
                    hostClass: 'col-xl-6'
                },
                newPassword: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 4}}
                    ],
                    placeholder: 'Repeat Password',
                    isLabelHidden: true,
                    label: 'Confirm your password',
                    type: InputType.PASSWORD,
                    hostClass: 'col-xl-6'
                }
            }
        },
        {
            controlType: 'group',
            key: 'registration_agreements',
            class: 'row register-form',
            controls: {
                registrationTermsAgreement: {
                    controlType: 'control',
                    defaultValue: false,
                    formControlType: FormControlType.CHECKBOX,
                    validationRules: [{name: ValidationRules.IS_TRUE, params: {}}],
                    placeholder: '',
                    label: '',
                    isLabelHidden: true,
                    isCheckboxLabelRaw: true,
                    checkboxLabel: `I agree to <a href="https://clinichunter.com/privacy-policy/" target="_blank">Privacy Policy</a>, <a href="https://clinichunter.com/personal-data-protection-policy/" target="_blank">personal data usage</a> and <a href="https://clinichunter.com/personal-data-information-clause-art-13-gdpr/" target="_blank">art. 13 GDPR</a>*`,
                    outputDataMapper: (data: any) => {
                        if (data === 'true' || data === 'on') {
                            return true;
                        }
                        if (data === 'false') {
                            return false;
                        }

                        return data;
                    },
                    hostClass: 'col-xl-12'
                },
            },
        },
        {
            controlType: 'group',
            key: 'registration_submit',
            class: 'row register-form',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'col-100 mb-0',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2',
                    defaultValue: null,
                    btnText: 'Register',
                },
            },
        },
    ],
};
