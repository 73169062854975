import { RootState } from "../reducers";
import { createSelector } from "redux-starter-kit";
import {IInquiryBaseState} from "./inquiryListSelectors";

export interface IInquiryItemState extends IInquiryBaseState {
  retrieved: any; // object
  eventSource: EventSource;
}

export interface IDeleteInquiryItemState extends IInquiryBaseState {
  deleted: any; // model for inquiry
}

export interface ICreateInquiryItemState extends IInquiryBaseState {
  created: any; // model for inquiry
}

export interface IUpdateInquiryItemState {
  retrieved: any; // model for inquiry
  retrieveError: string;
  retrieveLoading: boolean;
  updateError: string;
  updateLoading: boolean;
  eventSource: EventSource;
  updated: any; // model for inquiry
}

export const selectInquiryItem = (state: RootState): IInquiryItemState => {
  return state.inquiry.show;
};

export const selectDeleteInquiryItem = (state: RootState): IDeleteInquiryItemState => {
  return state.inquiry.del;
};

export const selectCreateInquiryItem = (state: RootState): ICreateInquiryItemState => {
  return state.inquiry.create;
};

export const selectUpdateInquiryItem = (state: RootState): IUpdateInquiryItemState => {
  return state.inquiry.update;
};

export const retrievedInquiryItemSelector = createSelector(
  [selectInquiryItem],
  (state: IInquiryItemState) => state.retrieved
);

export const inquiryItemLoadingSelector = createSelector(
  [selectInquiryItem],
  (state: IInquiryItemState) => state.loading
);

export const inquiryItemErrorSelector = createSelector(
  [selectInquiryItem],
  (state: IInquiryItemState) => state.error
);

export const inquiryItemEventSourceSelector = createSelector(
 [selectInquiryItem],
 (state: IInquiryItemState) => state.eventSource
);

export const inquiryItemDeleteErrorSelector = createSelector(
  [selectDeleteInquiryItem],
  (state: IDeleteInquiryItemState) => state.error
);

export const inquiryItemDeleteLoadingSelector = createSelector(
  [selectDeleteInquiryItem],
  (state: IDeleteInquiryItemState) => state.loading
);

export const inquiryItemDeletedSelector = createSelector(
  [selectDeleteInquiryItem],
  (state: IDeleteInquiryItemState) => state.deleted
);

export const inquiryItemCreateErrorSelector = createSelector(
  [selectCreateInquiryItem],
  (state: ICreateInquiryItemState) => state.error
);

export const inquiryItemCreateLoadingSelector = createSelector(
  [selectCreateInquiryItem],
  (state: ICreateInquiryItemState) => state.loading
);

export const inquiryItemCreatedSelector = createSelector(
  [selectCreateInquiryItem],
  (state: ICreateInquiryItemState) => state.created
);

export const inquiryItemUpdateRetrievedSelector = createSelector(
  [selectUpdateInquiryItem],
  (state: IUpdateInquiryItemState) => state.retrieved
);

export const inquiryItemUpdateRetrieveErrorSelector = createSelector(
  [selectUpdateInquiryItem],
  (state: IUpdateInquiryItemState) => state.retrieveError
);

export const inquiryItemUpdateRetrieveLoadingSelector = createSelector(
  [selectUpdateInquiryItem],
  (state: IUpdateInquiryItemState) => state.retrieveLoading
);

export const inquiryItemUpdateErrorSelector = createSelector(
  [selectUpdateInquiryItem],
  (state: IUpdateInquiryItemState) => state.updateError
);

export const inquiryItemUpdateLoadingSelector = createSelector(
  [selectUpdateInquiryItem],
  (state: IUpdateInquiryItemState) => state.updateLoading
);

export const inquiryItemUpdatedSelector = createSelector(
  [selectUpdateInquiryItem],
  (state: IUpdateInquiryItemState) => state.updated
);

export const inquiryItemUpdateEventSourceSelector = createSelector(
  [selectUpdateInquiryItem],
  (state: IUpdateInquiryItemState) => state.eventSource
);
