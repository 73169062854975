import { SubmissionError } from 'redux-form';
// import get from 'lodash/get';
import has from 'lodash/has';
// import mapValues from 'lodash/mapValues';

const MIME_TYPE = 'application/ld+json';

export function fetch(id: string, authToken: string, options = {}) {
  if ('undefined' === typeof (options as any).headers) (options as any).headers = new Headers();
  if (null === (options as any).headers.get('Accept'))
    (options as any).headers.set('Accept', MIME_TYPE);

  if (null === (options as any).headers.get('Authorization'))
    (options as any).headers.set('Authorization', `Bearer ${authToken}`);

  if (
    'undefined' !== (options as any).body &&
    !((options as any).body instanceof FormData) &&
    null === (options as any).headers.get('Content-Type')
  )
    (options as any).headers.set('Content-Type', "application/json");

  return (global as any).fetch(new URL(id, process.env.REACT_APP_AUTH_API_URL), options).then((response: any) => {
    if (response.ok) return response;

    return response.json().then(
      (json: any) => {
        const error =
          json['hydra:description'] ||
          json['hydra:title'] ||
          'An error occurred.';
        if (!json.violations) throw Error(error);

        let errors = { _error: error };
        json.violations.forEach((violation: any) =>
            (errors as any)[violation.propertyPath]
            ? ((errors as any)[violation.propertyPath] +=
                '\n' + (errors as any)[violation.propertyPath])
            : ((errors as any)[violation.propertyPath] = violation.message)
        );

        throw new SubmissionError(errors);
      },
      () => {
        throw new Error(response.statusText || 'An error occurred.');
      }
    );
  });
}

export function mercureSubscribe(url: any, topics: any) {
  topics.forEach((topic: any) =>
    url.searchParams.append('topic', new URL(topic, process.env.REACT_APP_AUTH_API_URL))
  );

  return new EventSource(url.toString());
}

export function normalize(data: any) {
  if (has(data, 'hydra:member')) {
    // Normalize items in collections
    data['hydra:member'] = data['hydra:member'].map((item: any) => normalize(item));

    return data;
  }

  // Flatten nested documents
  // return mapValues(data, value =>
  //   Array.isArray(value)
  //     ? value.map(v => get(v, '@id', v))
  //     : get(value, '@id', value)
  // );
  return data;
}

export function extractHubURL(response: any) {
  const linkHeader = response.headers.get('Link');
  if (!linkHeader) return null;

  const matches = linkHeader.match(
    /<([^>]+)>;\s+rel=(?:mercure|"[^"]*mercure[^"]*")/
  );

  return matches && matches[1] ? new URL(matches[1], process.env.REACT_APP_AUTH_API_URL) : null;
}
